import React from "react";
import Banner from "../containers/visuella/Banner";
import Designs from "../containers/visuella/Designs";
import Apps from "../containers/visuella/Apps";
import Testimonials from "../containers/visuella/Testimonials";
import MapSection from "../containers/visuella/MapSection";
import Faq from "../containers/visuella/Faq";
import Process from "../containers/visuella/Process";
import Services from "../containers/visuella/Services";

const HomePage = () => {
  return (
    <>
      <Banner />
      {/* <Process /> */}
      <Services />
      <Designs isLatest={true} />
      <Apps isLatest={true} />
      <Testimonials />
      <MapSection />
      <Faq />
    </>
  );
};
export default HomePage;
