import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import {
  BannerArea,
  BannerContent,
  Buttons,
  BannerContentWrapper,
} from "./banner.style";
import Button from "common/components/Button";
import playIcon from "common/assets/image/play.svg";
import Image from "common/components/Image";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const Data = useStaticQuery(graphql`
    query {
      allVisuellaJson {
        nodes {
          banner_data {
            banner_path {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { banner_path } = Data.allVisuellaJson.nodes[0].banner_data;

  const { t } = useTranslation();

  return (
    <BannerArea id="home">
      <BannerContentWrapper>
        <BannerContent>
          <h2>{ReactHtmlParser(t("banner.title"))}</h2>
          <p>{t("banner.subtitle")}</p>
          <Buttons>
            <Link to="#footer">
              <Button
                colors="primary"
                title={t("common.contactUs")}
                className="button-one"
              />
            </Link>

            <Link to="#design">
              <button className="button-two">
                <span className="play-icon">
                  <Image src={playIcon} alt="play Icon" />
                </span>
                <span className="btn-txt">
                  <span className="primary">{t("banner.explore")}</span>
                  {t("banner.ourServices")}
                </span>
              </button>
            </Link>
          </Buttons>
        </BannerContent>
        <div className="container">
          <div className="carousel">
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
            <img src={banner_path.publicURL} />
          </div>
        </div>
      </BannerContentWrapper>
    </BannerArea>
  );
};

export default Banner;
