import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const TestimonialWrapper = styled.div`
  padding-top: 10px;
  margin-left: -10px;
  margin-right: -10px;

  .masonryGrid {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`;

export const MobileSlider = styled.div`
  display: none;
  @media only screen and (max-width: 767px) {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 70px;
    margin-bottom: -30px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TestimonialItem = styled.div`
  width: calc(100% / 3);
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;

  @media only screen and (max-width: 991px) {
    width: calc(100% / 2);
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 12px;
  }

  @media only screen and (max-width: 767px) {
    width: 85%;
    flex: 0 0 85%;
    margin-right: 15px;
    margin-bottom: 0;
  }
`;

export const TestimonialItemInner = styled.div`
  position: relative;
  background-color: ${themeGet("colors.white")};
  border-radius: 10px;
  padding: 30px 27px;
  box-shadow: ${themeGet("colors.blueShadowColor")} 0px 15px 50px;
  transition: ease-in 0.25s;
  @media only screen and (max-width: 1199px) {
    padding: 20px 10px;
  }
  @media only screen and (max-width: 480px) {
    padding: 20px 10px;
  }
  p {
    color: ${themeGet("colors.textColor")};
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.3px;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
      line-height: 22px;
    }
    @media only screen and (max-width: 624px) {
      font-size: 12px;
      line-height: 22px;
    }
    > span {
      color: ${themeGet("colors.visuellaPrimary")};
      font-weight: 500;
    }
  }
`;

export const TestimonialHead = styled.div`
  display: flex;
  margin-bottom: 23px;
  position: relative;
  padding-right: 20px;
  @media only screen and (max-width: 991px) {
    margin-bottom: 10px;
    padding-right: 10px;
  }
  @media only screen and (max-width: 624px) {
    margin-bottom: 10px;
    padding-right: 10px;
  }
  h3 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    color: ${themeGet("colors.headingColor")};
    margin-bottom: 0px;
    margin-top: -7px;
    @media only screen and (max-width: 991px) {
      font-size: 14px;
      line-height: 20px;
    }
    @media only screen and (max-width: 624px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  p {
    color: ${themeGet("colors.visuellaPrimary")};
    font-size: 15px;
    line-height: 1.9;
    font-weight: 500;
    letter-spacing: -0.5px;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 624px) {
      font-size: 12px;
    }
  }
  a {
    color: ${themeGet("colors.visuellaPrimary")};
    transition: 0.15s ease-in-out;
    position: absolute;
    top: 0px;
    right: 0px;
    svg {
      width: 20px;
    }
    &:hover {
      color: ${themeGet("colors.linkColor")};
    }
  }
`;
export const AuthorImage = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 13px;
  > img {
    width: inherit;
    height: inherit;
    border-radius: inherit;
  }
`;
