import styled from 'styled-components';

export const ProcessItem = styled.div`
  position: relative;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: inline-block;
`;
