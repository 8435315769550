import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 480px) {
    display: none;
  }
  .countryIcon {
    position: absolute;
    height: 500px;
    width: 100%;
    .countryIconSingle {
      position: absolute;
      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        box-shadow: 0 0 0 0.8px ${themeGet("colors.visuellaPrimary")};
        border-radius: 50%;
        top: 50%;
        left: 50%;
        opacity: 0;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        animation: pulse 2.2s ease-out infinite;
        backface-visibility: hidden;
        pointer-events: none;
        z-index: 11;
      }
      &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: 0.25s ease-in-out;
        -webkit-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        z-index: 2;
      }
      &:nth-child(1) {
        position: absolute;
        top: 12%;
        left: 23%;
      }
      &:nth-child(2) {
        position: absolute;
        top: 24%;
        left: 15%;
      }
      &:nth-child(3) {
        position: absolute;
        top: 27%;
        left: 43%;
      }
      &:nth-child(4) {
        position: absolute;
        top: 14%;
        left: 45%;
      }
      &:nth-child(5) {
        position: absolute;
        top: 17%;
        left: 55%;
        @media only screen and (max-width: 768px) {
          top: 40%;
        }
      }
      &:nth-child(6) {
        position: absolute;
        top: 30%;
        left: 55%;
      }
      &:nth-child(7) {
        position: absolute;
        top: 22%;
        right: 24%;
      }
    }
  }
  @keyframes pulse {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }

    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
      opacity: 0;
    }
  }
`;

export const SectionWrapper = styled.section`
  overflow: hidden;
  padding-bottom: var(--paddingBottom);
  padding-top: var(--paddingTop);
  @media only screen and (max-width: 480px) {
    display: none;
  }
  .container {
    > header {
      max-width: 500px;
      @media only screen and (max-width: 480px) {
        max-width: 280px;
      }
    }
  }
`;