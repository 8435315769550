import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const BannerArea = styled.section`
  background: linear-gradient(
    ${themeGet("colors.visuellaSecondary")},
    ${themeGet("colors.visuellaPrimary")}
  );
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 120px;
  margin-bottom: 250px;
  padding-bottom: 10px;
  position: relative;
  text-align: center;
  @media (max-width: 575px) {
    padding-top: 60px;
  }
  .Container {
    display: flex;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 2.11;
    letter-spacing: -0.3px;
    color: ${themeGet("colors.white")};
    margin-bottom: 0;
    max-width: 515px;
    opacity: 0.8;
    @media (max-width: 1600px) {
      font-size: 16px;
      max-width: 466px;
    }
  }
  .ButtonWrap {
    display: flex;
    margin-top: 35px;
    align-items: center;
    @media (max-width: 1600px) {
      margin-top: 20px;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      color: ${themeGet("colors.white")};
      opacity: 0.6;
      line-height: 1;
      margin-bottom: 0;
      margin-left: 25px;
      @media (max-width: 1600px) {
        font-size: 14px;
      }
      @media (max-width: 575px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
  .Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 1;
    color: ${themeGet("colors.white")};
    padding: 21px 29px;
    background-color: ${themeGet("colors.primary")};
    transition: all 500ms ease;
    &:hover {
      background-color: ${themeGet("colors.white")};
      color: ${themeGet("colors.primary")};
    }
    i {
      margin-left: 10px;
      position: relative;
      top: 1px;
    }
    @media (max-width: 1600px) {
      font-size: 14px;
      padding: 16px 23px;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  .bannerMoc {
    position: absolute;
    bottom: 145px;
    right: 70px;
    @media (max-width: 1600px) {
      max-width: 750px;
      right: 43px;
    }
    @media (max-width: 1199px) {
      max-width: 500px;
    }
    @media (max-width: 768px) {
      max-width: 350px;
      right: 20px;
      bottom: 170px;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
`;

export const Col = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 575px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const BannerContent = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 1600px) {
    padding-top: 40px;
  }
  @media (max-width: 1440px) {
    padding-top: 40px;
    max-width: 1200px;
  }
  @media (max-width: 1366px) {
    max-width: 1000px;
  }
  @media (max-width: 1280px) {
    max-width: 650px;
  }
  @media (max-width: 1024px) {
    max-width: 700px;
  }
  @media (max-width: 768px) {
    max-width: 550px;
    padding-top: 40px;
  }
  @media (max-width: 480px) {
    padding-top: 40px;
  }
  p {
    font-size: 16px;
    line-height: 2;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    color: ${themeGet("colors.textColorLight")};

    @media (max-width: 480px) {
      font-size: 14px;
      line-height: 1.6;
    }
  }
  h2 {
    color: ${themeGet("colors.white")};
    font-weight: 500;
    font-size: 60px;
    line-height: 1.48;
    text-align: center;
    letter-spacing: -1px;
    max-height: 14vh;
    padding-left: 5px;
    padding-right: 5px;

    @media (max-width: 1536px) {
      font-size: 40px;
    }
    @media (max-width: 1280px) {
      font-size: 36px;
    }
    @media (max-width: 1024px) {
      font-size: 32px;
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 24px;
    }
    @media (max-width: 390px) {
      font-size: 22px;
    }
  }
  p {
    font-size: 16px;
    line-height: 2.11;
    max-width: 600px;
    margin: 0 auto;

    @media (max-width: 480px) {
      font-size: 14px;
      line-height: 1.6;
    }
  }
`;

export const Buttons = styled.div`
  gap: 15px;
  display: inline-grid;
  margin-top: 35px;
  grid-template-columns: repeat(2, 1fr);
  padding-left: 10px;
  padding-right: 10px;
  @media only screen and (min-width: 768px) {
    gap: 20px;
  }
  button {
    font-family: Inter, sans-serif;
    font-size: 14px;
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
  .button-one {
    border-radius: 60px;
    min-height: 50px;
    padding: 0 20px;
    background-color: ${themeGet("colors.white")};
    font-size: 16px;
    &:hover {
      background-color: ${themeGet("colors.primary")};
      color: ${themeGet("colors.white")};
    }

    @media only screen and (min-width: 768px) {
      min-height: 65px;
      padding: 0 25px;
    }
  }
  .button-two {
    background-color: ${themeGet("colors.transparent")};
    border: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    line-height: 22px;
    color: ${themeGet("colors.textColor")};
    .play-icon {
      background-color: ${themeGet("colors.whiteLowOpacity")};
      align-items: center;
      display: inline-flex;
      height: 55px;
      width: 55px;
      border-radius: 50%;
      justify-content: center;
      margin-right: 15px;
      transition: all 0.3s ease;
      img {
        width: 18px;
        @media only screen and (max-width: 480px) {
          margin-left: 2px;
          width: 17px;
        }
      }
      @media only screen and (min-width: 768px) {
        height: 65px;
        width: 65px;
      }
      position: relative;
      &:before {
        content: "";
        position: absolute;
        border: ${themeGet("colors.transparent")} solid 6px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
      }
    }
    &:hover {
      .play-icon {
        transform: scale(1.1);
      }
    }
    .btn-txt {
      text-align: left;
      color: ${themeGet("colors.white")};
    }
    .primary {
      color: ${themeGet("colors.white")};
      display: block;
      font-weight: 600;
    }
  }
`;

export const BannerContentWrapper = styled.div`
  .container {
    height: 500px;
    position: relative;
    overflow: visible;
    @media (max-width: 1024px) {
      height: 400px;
    }
    @media (max-width: 768px) {
      height: 300px;
    }
    @media (max-width: 480px) {
      height: 200px;
    }
  }

  .carousel {
    position: absolute;
    height: 700px;
    top: 0px;
    left: 0px;
    white-space: nowrap;
    animation: bannermove 350s linear infinite;
    display: flex;
    @media (max-width: 1024px) {
      height: 600px;
    }
    @media (max-width: 768px) {
      height: 500px;
    }
    @media (max-width: 480px) {
      height: 400px;
    }
  }
  .carousel:hover {
    animation-play-state: paused;
  }
  @keyframes bannermove {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @media (min-width: 1280px) {
    min-height: 100vh;
  }
`;
