import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Container from "common/components/UI/Container";

import {
  ServicesWrapper,
  SkillItem,
  SkillDetails,
  SkillIcon,
  SkillAbout,
} from "./services.style";
import { SectionHeader, SectionWrapper } from "../visuella.style";
import { useTranslation } from "react-i18next";
import Masonry from "react-masonry-component";

const masonryOptions = {
  originTop: true,
};

const Services = ({ row, col, skillTitle, skillDescription }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      allVisuellaJson {
        nodes {
          services {
            title_key
            description_key
            icon {
              publicURL
            }
          }
        }
      }
    }
  `);

  const { services } = data.allVisuellaJson.nodes[0];

  return (
    <SectionWrapper
      id="services"
      style={{
        "--paddingTop": "10px",
        "--paddingBottom": "10px",
      }}
    >
      <Container>
        <SectionHeader>
          <Heading as="h5" content={t("services.title")} />
          <Heading content={t("services.subtitle")} />
        </SectionHeader>
        <ServicesWrapper>
          <Masonry className="masonryGrid" options={masonryOptions}>
            {services.map(({ title_key, description_key, icon }, index) => (
              <SkillItem>
                  <SkillDetails>
                    <SkillIcon>
                      <Image
                        src={icon.publicURL}
                        alt={`skill-icon-${index + 1}`}
                      />
                    </SkillIcon>
                    <SkillAbout>
                      <Heading
                        content={t(`services.${title_key}`)}
                        {...skillTitle}
                      />
                      <Text
                        content={t(`services.${description_key}`)}
                        {...skillDescription}
                      />
                    </SkillAbout>
                  </SkillDetails>
              </SkillItem>
            ))}
          </Masonry>
        </ServicesWrapper>
      </Container>
    </SectionWrapper>
  );
};

Services.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  skillTitle: PropTypes.object,
  skillDescription: PropTypes.object,
  skillSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
};

Services.defaultProps = {
  sectionWrapper: {
    pt: ["60px", "80px", "100px", "110px", "140px"],
    pb: ["150px", "160px", "160px", "180px", "210px"],
    bg: "#f9f9f9",
  },
  secTitleWrapper: {
    mb: ["65px", "65px", "80px", "90px", "105px"],
  },
  secTitle: {
    fontSize: ["22px", "26px", "26px", "30px", "30px"],
    fontWeight: "700",
    color: "#302b4e",
    lineHeight: "1.34",
    mb: ["15px", "18px", "18px", "20px", "20px"],
    textAlign: "center",
  },
  secDescription: {
    fontSize: ["15px", "16px"],
    fontWeight: "400",
    color: "#43414e",
    lineHeight: "1.5",
    mb: "0",
    textAlign: "center",
    width: "600px",
    maxWidth: "100%",
    ml: "auto",
    mr: "auto",
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: ["-15px", "-15px", "-15px", "-25px", "-25px"],
    mr: ["-15px", "-15px", "-15px", "-25px", "-25px"],
  },
  col: {
    width: [1, 1, 1 / 2],
    pl: ["15px", "15px", "15px", "25px", "25px"],
    pr: ["15px", "15px", "15px", "25px", "25px"],
    mb: ["30px", "30px", "30px", "50px", "50px"],
  },
  skillTitle: {
    fontSize: ["16px", "18px", "18px", "20px", "20px"],
    fontWeight: "600",
    color: "#302b4e",
    mb: "12px",
  },
  skillDescription: {
    fontSize: ["15px", "15px", "15px", "16px", "16px"],
    fontWeight: "400",
    color: "#43414e",
    lineHeight: "1.5",
    mb: "0",
  },
  skillSuccessRate: {
    fontSize: ["15px", "15px", "14px", "15px", "16px"],
    fontWeight: "400",
    color: "#302b4e",
    lineHeight: "1.5",
    mb: "0",
  },
  successRateText: {
    ml: ".3em",
    display: ["none", "none", "none", "none", "inline-block"],
  },
};

export default Services;
