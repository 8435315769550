import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Masonry from "react-masonry-component";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Container from "common/components/UI/Container";
import {
  TestimonialWrapper,
  TestimonialItem,
  TestimonialItemInner,
  TestimonialHead,
  AuthorImage,
  MobileSlider
} from "./testimonial.style";
import { SectionWrapper, SectionHeader } from "../visuella.style";
import { useTranslation } from "react-i18next";

const masonryOptions = {
  originTop: true,
};

const TestimonialSection = () => {
  const data = useStaticQuery(graphql`
    query {
      allVisuellaJson {
        nodes {
          testimonials {
            author
            username
            testimonial
            image {
              publicURL
            }
          }
        }
      }
    }
  `);

  const { testimonials } = data.allVisuellaJson.nodes[0];

  const { t } = useTranslation();

  const renderTestimonials = () => (
    testimonials.map((item, index) => (
      <TestimonialItem key={index}>
        <TestimonialItemInner>
          <TestimonialHead>
            <AuthorImage>
              <Image src={item.image.publicURL} alt={item.author} />
            </AuthorImage>
            <Box>
              <Heading as="h3" content={item.author} />
              <Text content={item.username} />
            </Box>
          </TestimonialHead>
          <Text content={item.testimonial} />
        </TestimonialItemInner>
      </TestimonialItem>
    ))
  );

  return (
    <SectionWrapper
      id="testimonials"
      style={{
        "--paddingTop": "10px",
        "--paddingBottom": "30px",
      }}
    >
      <Container>
        <SectionHeader>
          <Heading as="h5" content={t("testimonials.title")} />
          <Heading content={t("testimonials.subtitle")} />
        </SectionHeader>
        <TestimonialWrapper>
          <Masonry className="masonryGrid" options={masonryOptions}>
            {renderTestimonials()}
          </Masonry>
          <MobileSlider>
            {renderTestimonials()}
          </MobileSlider>
        </TestimonialWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default TestimonialSection;
