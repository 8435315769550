import styled from 'styled-components';

export const ServicesWrapper = styled.div`
  padding-top: 30px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  .masonryGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
`;

export const SkillItem = styled.div`
  width: calc(100% / 2);
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 25px;
  @media only screen and (max-width: 991px) {
    width: calc(100%);
    margin-bottom: 12px;
  }
  @media only screen and (max-width: 624px) {
    width: calc(100%);
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 12px;
  }
`;

export const SkillDetails = styled.div`
  padding: 30px 30px 30px 30px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0.521px 2.954px 50px 0px rgba(101, 106, 160, 0.1);
  @media (max-width: 1199px) {
    padding: 35px 35px 35px 35px;
  }
  @media (max-width: 990px) {
    padding: 30px 30px 30px 30px;
  }
  @media (max-width: 575px) {
    padding: 25px 25px 25px 25px;
  }
`;

export const SkillIcon = styled.div`
  flex: 0 0 130px;
  max-width: 130px;
  @media (max-width: 990px) {
    flex: 0 0 55px;
    max-width: 55px;
    margin-right: 30px;
  }
  @media (max-width: 575px) {
    flex: 0 0 45px;
    max-width: 45px;
    margin-right: 20px;
  }
`;

export const SkillAbout = styled.div``;
  