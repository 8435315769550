import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { plusCircled } from "react-icons-kit/ionicons/plusCircled";
import { minusCircled } from "react-icons-kit/ionicons/minusCircled";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "common/components/Accordion";
import { FaqSection } from "./faq.style";
import { SectionHeader } from "../visuella.style";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

const Faq = () => {
  const data = useStaticQuery(graphql`
    query {
      allVisuellaJson {
        nodes {
          faq {
            questionKey
            answerKey
          }
        }
      }
    }
  `);
  const { faq } = data.allVisuellaJson.nodes[0];

  const { t } = useTranslation();

  return (
    <FaqSection
      id="faq"
      style={{
        "--paddingTop": "10px",
        "--paddingBottom": "60px",
      }}
    >
      <Container>
        <SectionHeader>
          <Heading as="h5" content={t("faq.title")} />
          <Heading content={t("faq.subtitle")} />
        </SectionHeader>
        <Accordion>
          <Fragment>
            {faq.map((item) => (
              <AccordionItem key={item}>
                <Fragment>
                  <AccordionTitle>
                    <Fragment>
                      <Heading as="h3" content={t(`faq.${item.questionKey}`)} />
                      <IconWrapper className="icon-wrapper">
                        <OpenIcon>
                          <Icon icon={minusCircled} size={18} />
                        </OpenIcon>
                        <CloseIcon>
                          <Icon icon={plusCircled} size={18} />
                        </CloseIcon>
                      </IconWrapper>
                    </Fragment>
                  </AccordionTitle>
                  <AccordionBody>
                    <Text
                      content={ReactHtmlParser(`${t(`faq.${item.answerKey}`)}`)}
                    />
                  </AccordionBody>
                </Fragment>
              </AccordionItem>
            ))}
          </Fragment>
        </Accordion>
      </Container>
    </FaqSection>
  );
};

export default Faq;
