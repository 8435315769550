import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Container from "common/components/UI/Container";
import Text from "common/components/Text";
import GatsbyImage from "common/components/GatsbyImage";
import { ImageWrapper, SectionWrapper } from "./mapSection.style";
import { SectionHeader } from "../visuella.style";

import countryIcon from "common/assets/image/map/countryIcon.png";
import { useTranslation } from "react-i18next";

const MapSection = () => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "image/map/map.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1102
            placeholder: BLURRED
            formats: [AUTO, PNG, AVIF]
          )
        }
      }
    }
  `);

  const { t } = useTranslation();

  return (
    <SectionWrapper
      id="map"
      style={{
        "--paddingTop": "40px",
        "--paddingBottom": "60px",
      }}
    >
      <Container width="1200px">
        <SectionHeader>
          <Heading as="h5" content={t("map.title")} />
          <Heading content={t("map.subtitle")} />
          <Text content={t("map.countries")} />
        </SectionHeader>
        <ImageWrapper>
          <GatsbyImage
            src={
              (data.illustration !== null) | undefined
                ? data.illustration.childImageSharp.gatsbyImageData
                : {}
            }
            alt="Privacy Illustration"
          />
          <div className="countryIcon">
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
            <div className="countryIconSingle">
              <Image
                className="countryIconImg"
                src={countryIcon}
                alt="Country Icon"
              />
            </div>
          </div>
        </ImageWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default MapSection;
